/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import { graphql, Link } from 'gatsby';
import { Layout, Container } from '../components';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import * as analytics from '../utils/analytics';
import { checkLanguageVersion } from '../utils/contentful';
import { filterByChannel } from '../hooks/useBlogPosts';

const NewsPage = ({ pageContext, data }) => {
  analytics.usePageCategory('news');
  const translate = getTranslate(useSelector((state) => state.localize));
  const locale = useSelector((state) => state.session.locale);

  return (
    <Layout title={translate('news')} paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Container variant="text" sx={{ py: [3, 4] }}>
        <Styled.h1 sx={{ mb: 4 }}>{translate('news')}</Styled.h1>

        <Flex sx={{ flexDirection: 'column' }}>
          {data.allContentfulBlogPost.edges
            .filter(filterByChannel)
            // get news for specific locale
            .filter(
              (edge) => edge.node.node_locale.startsWith(locale) && checkLanguageVersion(locale, edge?.node?.languages)
            )
            .map(({ node }, key) => (
              <Link key={key} to={`/news/${node.slug}`} sx={{ color: 'text', mb: 4, ':hover': { color: 'primary' } }}>
                <Box>{node.publishTime}</Box>
                <Styled.h2 sx={{ m: 0, fontSize: [2, 3] }}>{node.title}</Styled.h2>
              </Link>
            ))}
        </Flex>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulBlogPost(filter: { postType: { ne: "Blog" } }, sort: { fields: [publishTime], order: DESC }) {
      edges {
        node {
          title
          slug
          publishTime(formatString: "D.M.Y")
          node_locale
          description
          keywords
          updatedAt
          languages
          channel
        }
      }
    }
  }
`;

export default NewsPage;
